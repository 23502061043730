import Vue from 'vue/dist/vue.min';
import {ecoslider} from './components/ecoslider'

export function eco(controller , scene){
    const v = new Vue({
        el: '#ecovue',
		components: {
			'ecoslider': ecoslider()
		}
    });

};