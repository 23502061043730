import {index} from './index';

export function loading(controller , scene){
    if(document.getElementById('intro')){

        const images = document.getElementsByTagName('img');
        const loading = document.getElementById('intro');
		const video = document.getElementById('videos');
		if(video){
			video.autoplay = false;
		}
		
		
        let percent = 0;
        let finish = false;
        let leng = images.length;
        let imgCount = 0;
        let baseCount = 0;
        let current;
		
		document.body.classList.add('hidden');
		
		loading.classList.add('visible');

        for (let i = 0; i < images.length; i++) {
          let img = new Image();
          img.onload = function() {
              imgCount += 1;
          }
          img.onerror = function() {
              imgCount += 1;
          }
          img.src = images[i].src;
        };

        function disp(result){
            setTimeout(function(){
                let num = percent;
                if(result > num | num < 101){
                    percent = num +1;
                    disp(result);
                }else{
                    nowLoading();
                }

                if(num == 100){
                    if(!finish){
                        finish = true;
                        setTimeout(function(){
                            setTimeout(function(){
                                loading.classList.add('active');
                            },2800);
                            setTimeout(function(){
								index(controller , scene);
                            },3600);
                            setTimeout(function(){
								document.body.classList.remove('hidden');
                                loading.style.display = 'none';
                            },4400);
                        },800);
                    }
                }
            },8);
        }
        function nowLoading(){
          if(baseCount <= imgCount) {
              current = Math.floor(baseCount / leng * 100);
              disp(current);
              baseCount ++;
          }
        }

        if(leng == 0){
            loading.style.display = 'none';
        }else{
            nowLoading();
        }
    }
}