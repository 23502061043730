import ScrollMagic from 'scrollmagic';

import {scrolEvents} from './default';
import {loading} from './loading';
import {index} from './index';
import {recruit} from './recruit';
import {eco} from './eco';
import {works} from './works';
import {breadcrumb} from './breadcrumb';
import {vm} from './vm';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	vm();
	
    // LOADING 処理
    const keyName = 'visited';
    const keyValue = true;

    if (!sessionStorage.getItem(keyName)) {
        sessionStorage.setItem(keyName, keyValue);
        loading(controller , scene);
    } else {
        const intro = document.getElementById('intro');
        if(intro){
            intro.remove();
        }
        index(controller , scene);
    }

    
    scrolEvents(controller , scene);
    breadcrumb(controller , scene);
	recruit(controller , scene);
	works(controller , scene);
	eco(controller , scene);
});