import $ from 'jquery';
import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';
import ScrollMagic from 'scrollmagic';

export function index(controller , scene){
	const video = document.getElementById('videos');
	if(video){
		video.autoplay = true;
		video.play();
	}
	const spvideo = document.getElementById('spvideos');
	if(spvideo){
		spvideo.autoplay = true;
		spvideo.play();
	}
	
	const fv = document.getElementById('fv');
	if(fv){
		fv.classList.add('active');
	}
	
	const score = document.getElementById('score');
    if(score){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: '#score',
                offset: -300
            })
            .on("enter", function (event) {
                const parents = document.getElementsByClassName('score-child');
                for(let i = 0; i < parents.length; i++){
                    counter(parents[i], parents[i].dataset.score); 
                }
            })
            .reverse(false)
            .addTo(controller)
        )
    }
	
	const workslide = document.getElementById('workslide');
	if(workslide){
		const swiper = new Swiper("#workslide", {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 600,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: ".works-next",
				prevEl: ".works-prev",
			},
			pagination: {
				el: ".works-pagination",
				clickable: true,
			},
		});
	}
	
	const featureslide = document.getElementById('featureslide');
	if(workslide){
		const swiper = new Swiper("#featureslide", {
			slidesPerView: 1,
			spaceBetween: 30,
			effect: "fade",
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			speed: 600,
		});
	}
	

	function counter(el, data){
		let num = el.innerText.replace(/,/g, '');
		num = parseInt(num);
		data = parseInt(data);
		
		el.innerText = (num + data/200).toLocaleString();
		
        if(num + data/200 != data){
            setTimeout(function(){
                counter(el,data);
            },8);
        }else{
			el.classList.add('animation');
		}
	}
	
	$(function(){
		$('.j-tab li').on('click',function(){
			let target = $(this).attr('data-tabindex');
			
			if(!$(this).is('active')){
				$('.j-tab li').removeClass('active');
				$(this).addClass('active');
				
				$('.j-content:visible').stop().slideToggle(300,function(){
					$('.j-content').eq(target - 1).stop().delay(300).slideToggle(300);
				});
			}
		});
	});
};