import $ from 'jquery';

export function recruit(controller , scene){
	
	const recl = document.getElementById('recruit');
	if(recl){
		recl.classList.add('active');
	}
	
	if(document.getElementById('viewdata')){
		particlesJS.load('viewdata', '/include/json/particlesjs-config.json');
	}
	
	$(function(){
		$('.j-r-tab li').on('click',function(){
			let target = $(this).attr('data-tabindex');
			
			if(!$(this).is('active')){
				$('.j-r-tab li').removeClass('active');
				$(this).addClass('active');
				
				$('.j-r-content:visible').stop().slideToggle(300,function(){
					$('.j-r-content').eq(target - 1).stop().delay(300).slideToggle(300);
				});
			}
		});
	});
};