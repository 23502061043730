import $ from 'jquery';
import Swiper from 'swiper/swiper-bundle.min';

export function works(controller , scene){
	const workswipe = document.getElementById('workswipe');
	if(workswipe){
		var swiper = new Swiper("#workswipe", {
				centeredSlides: true,
				slidesPerView: 1,
				spaceBetween: 15,
				loop: true,
				speed: 600,
				autoplay: {
					delay: 2500,
					disableOnInteraction: false,
				},
				pagination: {
					el: ".works-pagination",
					clickable: true,
				},
				navigation: {
					nextEl: ".works-button-next",
					prevEl: ".works-button-prev",
				},
                breakpoints: {
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 24
                    },
                }
			}
		);
	}


};