import Swiper from 'swiper/swiper-bundle.min';

export function ecoslider(){
    const temp = {
        data: function(){
            return {
                flag: false,
				img: '',
				txt: ''
            }
        },
        template: 
        `
			<div id="swipeElem">
				<div class="swiperbox">
					<div class="swiper-container ecoswipe" id="ecoswipe">
						<div class="swiper-wrapper">

							<div class="swiper-slide ecoswipe__item" v-for="item in arg">
								<figure><a @click="photoview(item.large, item.txt)"><img :src="item.img" alt="写真"></a></figure>
							</div>

						</div>
					</div>
					<div class="eco-button-next"><i class="fas fa-chevron-right"></i></div>
					<div class="eco-button-prev"><i class="fas fa-chevron-left"></i></div>
					<div class="eco-pagination"></div>
				</div>
				<transition name="scale">
					<div v-if="flag" class="swipelay" :class="{'active': flag}">
						<div class="swipelay__close" @click="flag = false"><i class="fas fa-times"></i>閉じる</div>
						<div class="swipelay__box">
							<figure>
								<img :src="img" alt="写真">
								<figcaption v-html="txt"></figcaption>
							</figure>
						</div>
					</div>
				</transition>
			</div>
        `,
		mounted: function(){
			var swiper = new Swiper("#ecoswipe", {
					centeredSlides: false,
					slidesPerView: 2,
					spaceBetween: 15,
					loop: false,
					speed: 600,
					autoplay: {
						delay: 2500,
						disableOnInteraction: false,
					},
					pagination: {
						el: ".eco-pagination",
						clickable: true,
					},
					navigation: {
						nextEl: ".eco-button-next",
						prevEl: ".eco-button-prev",
					},
					breakpoints: {
						640: {
							slidesPerView: 3,
							spaceBetween: 24
						},
					}
				}
			);
		},
		methods: {
			photoview: function(img,txt){
				this.flag = true;
				this.img = img;
				this.txt = txt;
			}
		},
		props: {
			arg: {type:[Array], default:()=>[]}
		}
    }
    return temp;
};